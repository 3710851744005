import {CardBody, CardFooter, CardTitle} from "../card";
import {APP_NAME} from "../../../constants";
import React from "react";
import {MainLayout} from "./main-layout";

export const AppFooter = ()=> {
    return  <footer className="relative">
        <MainLayout className="relative">
            <CardTitle id="contact" className='mt-[50px] lg:mt-[321px] lg:mb-[51px]' rightText="connect with us">Contact Us</CardTitle>
            <CardBody className="flex flex-col items-center">
                <div className="flex items-center space-x-[40px]">
                    <img src="/images/icons/telegram.svg" className="w-[100px] h-[100px]" alt="telegram" />
                    <img src="/images/icons/twitter.svg" className="w-[100px] h-[100px]" alt="twitter" />
                </div>
                <div className="logo-name font-ramaraja font-[400] text-[100px] lg:text-[178px] mt-[56px]">{APP_NAME}</div>
            </CardBody>
        </MainLayout>
        <CardFooter className="text-white text-center pb-[20px] text-[18px] lg:h-[194px] flex flex-col justify-center lg:text-[20px] font-bold lg:mt-[182px] box-border bg-[rgba(152,77,169,0.03)] relative z-[10]">
            <div className="lg:max-w-[90%] lg:mx-auto">
                <p className="mb-[40px]">{APP_NAME} is a meme coin with no intrinsic value or expectation of financial return.
                    There is no formal team or roadmap, the coin is completely useless and for entertainment purposes only.</p>
                <p>© 2024 by <b>{APP_NAME}</b>. All rights reserved!</p>
                <a href="/disclaimer" className="underline">DISCLAIMER</a>
            </div>
        </CardFooter>
        <img src="/images/footer/decoration-left.svg" className="absolute left-0 bottom-0 w-[20%] lg:w-auto"  />
        <img src="/images/footer/decoration-right.svg" className="absolute right-0 bottom-0 w-[20%] lg:w-auto"  />
    </footer>
}