import {FC, ReactNode} from 'react';
import clsxm from "../../../lib/clsxm";
import {BodyLayout} from "../layout";
interface CardTitleProps {
    titleClassName?:string;
    className?: string;
    children: ReactNode;
    rightText?:string;
    isLine?:boolean;
    id?:string;
}
export const CardTitle:FC<CardTitleProps> = ({titleClassName, className, id, children,rightText,isLine = true}) => {
    return (
        <BodyLayout>
        <div id={id} className={clsxm("flex lg:flex-row lg:items-center w-full flex-col items-start mb-[20px]",className)}>
            <div className={clsxm("text-white min-w-fit text-[48px] font-ramaraja",titleClassName)}>{children}</div>
            {isLine && <div className="bg-gradient-transparent-to-white h-[3px] w-full ml-auto hidden lg:block"></div>}
            {rightText && <div className="text text-white lg:ml-[32px] text-[35px] min-w-fit">{rightText}</div>}
        </div>
        </BodyLayout>
    )
}