import { Button } from "../button";
import { MainLayout } from "./main-layout";
import clsxm from "../../../lib/clsxm";
import { HiMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { useState } from "react";
import { APP_NAME } from "../../../constants";

export const AppHeader = () => {
  const menus = [
    {
      name: "About",
      url: "#about",
    },
    {
      name: "How To Buy",
      url: "#how-to-buy",
    },
    {
      name: "Contact",
      url: "#contact",
    },
    {
      name: "Telegram",
      url: "https://t.me/poorpepeofficial",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/callthempoor",
    },
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="h-[90px] lg:h-[132px] w-full flex items-center z-[99999] justify-between fixed top-0 left-0 lg:left-[5%] px-[10px] box-border lg:px-0 lg:max-w-[90%] lg:mx-auto">
      <div className="logo-wrapper min-w-[141px] w-[141px] h-[104px] lg:w-[104px] lg:h-[104px] flex items-center justify-center relative z-[99999]">
        <div className="logo text-white text-[40px] font-ramaraja mt-[10px]">
          <a href="/">{APP_NAME}</a>
        </div>
      </div>

      <ul
        className={clsxm(
          "fixed left-0 top-0 flex-col w-full pr-[20px] h-screen bg-black lg:bg-transparent",
          isMenuOpen ? "flex pt-[90px]" : "hidden",
          "lg:flex lg:flex-row lg:items-center lg:ml-auto lg:mr-[5%] lg:pr-0 lg:static lg:h-auto"
        )}
      >
        {menus.map((menu, index) => (
          <li
            key={index}
            className={clsxm(
              "text-grey text-[20px] cursor-pointer h-[40px] flex items-center justify-end w-full",
              "lg:hover:text-white lg:justify-center"
            )}
            onClick={() => setIsMenuOpen(false)}
          >
            <a href={menu.url}>{menu.name}</a>
          </li>
        ))}

        <li className="flex justify-end mt-[20px] lg:hidden">
          <Button
            className={clsxm(
              "uppercase w-[140px] h-[53px] rounded-[30px] ",
              "lg:w-[208px] lg:h-[59px] lg:rounded-[40px]"
            )}
          >
            buy now
          </Button>
        </li>
      </ul>

      <div
        className="lg:hidden text-white text-[48px] relative z-[99999]"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? <IoClose /> : <HiMenuAlt3 />}
      </div>

      <div className="action-wrapper hidden lg:flex">
        <Button
          className={clsxm(
            "uppercase w-[140px] h-[53px] rounded-[30px] ",
            "lg:w-[208px] lg:h-[59px] lg:rounded-[40px]"
          )}
        >
          buy now
        </Button>
      </div>
    </header>
  );
};
