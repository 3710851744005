import {BodyLayout, Button, CardBody, CardFooter, CardTitle, MainLayout} from "../../core";
import React from "react";
import {HomeAbout} from "./home-about";
import {APP_NAME} from "../../../constants";
import clsxm from "../../../lib/clsxm";

export const Home = () => {

    const overviews = [
        {
            picture:"img_1.png",
            title:"solana",
            intro:"Speedy Gonzalez of blockchain, yet lacking the drama. Where's the punchline?"
        },
        {
            picture:"img_2.png",
            title:"DOG WIF HAT:",
            intro:"Impossibly adorable. Financial wisdom? Not so much. But dog with a hat? Magic."
        },
        {
            picture:"img_3.png",
            title:"ETHEREUM:",
            intro:"The tortoise in the race, wake me up, wen we etf."
        },
        {
            picture:"img_4.png",
            title:"FIAT:",
            intro:"The grand illusion. The ultimate magic trick. Infinite supply, a centrally orchestrated tragic comedy, where we are the punchline, and a penchant for inflation that's almost endearing if it wasn't robbing you every second of every day."
        },
    ]



    const specs = [
        {
            picture:"img_1.png",
            title:"Minting the Economy:",
            intro:"Inspired by the grandiose numbers of M0, M1, M2 (21.27 Trillion tokens, because why not?)"
        },
        {
            picture:"img_2.png",
            title:"The Burn Spectacle:",
            intro:"1 Trillion tokens vanish every 100 days—like clockwork, until we reach 1 trillion token supply."
        },
        {
            picture:"img_3.png",
            title:"Taxation With Representation:",
            intro:"0% buy/sell tax because we feel for you."
        },
    ]


    const helps = [
        {
            picture:"img_1.png",
            title:"create a wallet",
            intro:"Download phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to raydium.io."
        },
        {
            picture:"img_2.png",
            title:"GET SOME SOL",
            intro:"Have SOL in your wallet to switch to $POOR. If you don’t have any SOL, you can buy directly on phantom, transfer from another wallet, or buy on another exchange and send it to your wallet."
        },
        {
            picture:"img_3.png",
            title:"GO TO RAYDIUM",
            intro:"Go to raydium.io/swap in google chrome or on the browser inside your phantom app. Connect your wallet. Paste the $POOR token address into raydium, select POOR, and confirm. When phantom prompts you for a wallet signature, sign."
        },
        {
            picture:"img_4.png",
            title:"SWITCH SOL FOR $POOR",
            intro:"Switch SOL for $POOR. We have NO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility."
        },
    ]


    return <>
        <HomeAbout />
        <CardTitle titleClassName="text-[64px]" className="lg:mb-[100px] mt-[50px]" rightText="about">{APP_NAME}</CardTitle>
        <BodyLayout>
            <CardBody className="lg:h-[643px] flex flex-col items-center">
                <div className="flex flex-col lg:flex-row">
                    <div className="user-picture mb-[20px] lg:mb-0 w-full lg:w-[425px] lg:min-w-[425px] lg:h-[425px] bg-lightgrey rounded-[10px]">
                        <img src="/images/character.png" className="w-full h-full" />
                    </div>
                    <div className="lg:ml-[40px]">
                        <div className='text-[24px] lg:text-[38px] text-center flex flex-col'>
                            <div><span className="bg-clip-text text-transparent bg-gradient-green-to-blue font-ramaraja">WE CALL THEM POOR ({APP_NAME})</span> -</div>
                            <p>The Only Token You’ll Regret Not Regretting</p>
                        </div>
                        <p className='text-[16px] font-bold mt-[20px]'>A Prophetic Warning from the  Oracle, Mike Moon Saylor:</p>

                        <div className='w-full h-[126px] flex items-end flex-col my-[20px] justify-center italic text-[20px]'>
                            <p>"People that use fiat as a store of value, </p>
                            <p className="mt-4">WE CALL THEM POOR."</p>
                        </div>
                        <p className='text-left text-[16px] font-bold'>Behold, the paradox that is {APP_NAME}: so blatantly ironic, it double-dares you to dismiss it. Yet here you are, contemplating its existence.</p>
                    </div>
                </div>
                <div className="actions flex items-center space-x-[20px] lg:space-x-[40px] mt-[40px]">
                    <Button onClick={() => window.location.href="https://t.me/poorpepeofficial" } className={"bg-gradient-purple w-[170px] lg:w-[280px] h-[50px] lg:h-[64px] rounded-[40px] text-white font-bold"}>
                        <span className="mr-[10px]">telegram</span>
                        <img src="/images/icons/telegram-white.svg" className="w-[32px] h-[32px]" alt="telegram" />
                    </Button>
                    <Button onClick={() => window.location.href="https://twitter.com/callthempoor" } className={"bg-gradient-purple w-[170px] lg:w-[280px] h-[50px] lg:h-[64px] rounded-[40px] text-white font-bold"}>
                        <span className="mr-[10px]">twitter</span>
                        <img src="/images/icons/twitter-white.svg" className="w-[32px] h-[32px]" alt="twitter" />
                    </Button>
                </div>
            </CardBody>

            <CardFooter className="lg:grid lg:grid-cols-2 mt-[24px] lg:mt-[100px] grid-cols-1 space-y-[20px] lg:space-y-0 lg:space-x-[24px]">
                <div className="lg:h-[311px] relative bg-[rgba(43,43,176,0.31)] rounded-[25px] text-white p-[24px] lg:py-[54px] lg:px-[39px] box-border" style={{
                    backgroundImage: "url(/images/small-card-background.svg)",
                    backgroundRepeat:"no-repeat",
                    backgroundSize:"cover",
                }}>
                    <div className="text-[24px] lg:text-[32px] font-[400] mb-[20px]">Why {APP_NAME} Is the inverse of FIAT:</div>
                    <p className="text-left text-white text-[14px] lg:text-[16px] w-[90%]">Unbelievably Futile Object: What were we thinking, launching {APP_NAME}? It's the cryptocurrency equivalent of a joke that's so bad, it's good. {APP_NAME} has zero intrinsic value; it's an enigma wrapped in a digital enigma.</p>
                </div>
                <div className="lg:h-[311px] relative bg-[rgba(43,43,176,0.31)] rounded-[25px] text-white p-[24px] lg:py-[54px] lg:px-[39px] box-border" style={{
                    backgroundImage: "url(/images/small-card-background.svg)",
                    backgroundRepeat:"no-repeat",
                    backgroundSize:"cover",
                }}>
                    <div className="text-[32px] font-[400] mb-[20px]">The Self-Fulfilling Prophecy:</div>
                    <p className="text-left text-white text-[14px] lg:text-[16px] w-[90%]">Buy {APP_NAME}, and flirt with destiny. Dive into these turbulent waters, and you're either shark bait or riding the crypto kraken.</p>
                </div>
            </CardFooter>
        </BodyLayout>


        <CardTitle className='mt-[50px] lg:mt-[321px] lg:mb-[44px]' titleClassName="text-[24px]" rightText="overview">The Comical Carousel of Crypto</CardTitle>
        <CardBody className="bg-transparent relative">
            <BodyLayout className="relative z-[2]">
                <div className="lg:grid space-y-[22px]">
                    {overviews.map((overview,index)=>{
                        return <CardBody key={index} className={clsxm("bg-gradient-translucency flex items-center rounded-[25px] lg:px-[24px] lg:py-[32px] box-border w-full lg:w-[610px]",
                            index % 2 !== 0 ? "flex-row-reverse justify-self-end" : "")}>
                            <div className="user-picture w-[105px] min-w-[105px] h-[91px] rounded-[6px]">
                                <img src={`/images/overview/${overview.picture}`} alt={overview.title} />
                            </div>
                            <div className={clsxm('flex flex-col',
                                index % 2 !== 0 ? 'mr-[12px] lg:mr-[42px]' : 'ml-[12px] lg:ml-[42px]'
                            )}>
                                <div className='uppercase text-[20px] font-bold'>{overview.title}</div>
                                <p className="text-[14px] mt-[10px] lg:mt-[26px] line-clamp-3">{overview.intro}</p>
                            </div>
                        </CardBody>
                    })}
                </div>
            </BodyLayout>
            <img src="/images/overview/halo-left.svg" className="absolute left-0 -top-1/2 z-[1]" />
            <img src="/images/overview/halo-right.svg" className="absolute right-0 top-0 z-[1]" />
        </CardBody>


        <CardTitle className='mt-[50px] lg:mt-[321px] lg:mb-[150px]' isLine={false}>
            <div className='text-white text-left flex flex-col items-start'>
                <span className='font-[400] text-[32px] lg:text-[42px]'>For those Who Choose Fiat,</span>
                <span className='font-[800] font-montserrat text-[28px] lg:text-[32px]'>We Call them Poor.</span>
            </div>
        </CardTitle>

        <CardBody className="bg-transparent relative p-0">
            <BodyLayout className="relative z-[2]">
                <CardBody className="flex flex-col lg:flex-row">
                    <div className="picture-wrapper lg:w-[451px] lg:min-w-[451px] lg:h-[461px] w-full lg:h-[461px] rounded-[6px]">
                        <img src="/images/specs/thumb.png"  />
                    </div>
                    <div className="flex flex-col items-center mt-[16px] lg:mt-0 lg:ml-[125px]">
                        <div className='bg-clip-text text-transparent bg-gradient-green-to-blue text-[32px] lg:text-[48px] font-[400] mb-[32px] font-ramaraja'>{APP_NAME} Specs:</div>
                        <div className="list-group space-y-[32px]">
                            {specs.map((spec,index)=> {
                                return <div key={index} className="list-group-item text-white flex">
                                    <div className="img-wrapper w-[100px] min-w-[100px] h-[95px]">
                                        <img src={`/images/specs/${spec.picture}`} alt={spec.title} />
                                    </div>
                                    <div className="ml-[12px] lg:ml-[48px]">
                                        <div className="text-[18px] lg:text-[24px] font-[400] font-ramaraja">{spec.title}</div>
                                        <p className="text-[12px] lg:text-[14px] font-[500] mt-[10px]">{spec.intro}</p>
                                    </div>
                                </div>
                            })}

                        </div>
                    </div>
                </CardBody>
            </BodyLayout>
            <img src="/images/specs/colour-line.svg" className="absolute left-0 top-0 z-[1]" />
        </CardBody>

        <CardTitle id="how-to-buy" className='mt-[50px] lg:mt-[321px] lg:mb-[51px]' rightText="follow instructions">How to Buy</CardTitle>
        <CardBody className="relative p-0">
            <BodyLayout className="relative z-[2]">
                <div className="list-group space-y-[16px] lg:space-y-[51px] lg:w-[80%] lg:mx-auto">
                    {helps.map((help,index)=>{
                        return <div key={index} className={clsxm("text-white px-[12px] py-[24px] flex bg-gradient-transparent-to-blue rounded-[25px] w-full box-border",
                        "lg:h-[205px] lg:py-[37px] lg:px-[24px]")}>
                            <div className="img-wrapper w-[100px] min-w-[100px] h-[95px]">
                                <img src={`/images/help/${help.picture}`} alt={help.title} />
                            </div>
                            <div className="ml-[12px] lg:ml-[48px]">
                                <div className="text-[18px] lg:text-[24px] font-[400]">{help.title}</div>
                                <p className="text-[12px] lg:text-[14px] font-[500] mt-[10px]">{help.intro}</p>
                            </div>
                        </div>
                    })}
                </div>
            </BodyLayout>

            <img src="/images/help/halo-right.svg" className="absolute  right-0 top-0 z-[1]" />

        </CardBody>


        <CardTitle className='mt-[50px] lg:mt-[321px] lg:mb-[50px]' rightText="hmmm...">Buying {APP_NAME}? </CardTitle>
        <CardBody className="flex items-center flex-col justify-center text-white">
            <div className="flex items-center text-[28px] lg:text-[36px]">
                <span className="text-white mr-1">So, go on. </span>
                <span className="bg-clip-text text-transparent bg-gradient-green-to-blue font-[800]">Buy {APP_NAME}.</span>
            </div>
            <p className="italic text-[36px] font-[300] my-[22px] lg:my-[51px]">Or don't. Your call.</p>
            <p className="text-center text-[16px] lg:text-[20px] leading-[1.2]">
                We're just here, orchestrating the greatest show on the blockchain, proving that sometimes, the best investments are the ones you thought at first made you poor.
            </p>
        </CardBody>






    </>
}
