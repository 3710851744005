import clsxm from "../../../lib/clsxm";
import {FC,ReactNode} from "react";

interface CardBodyProps {
    className?: string;
    children: ReactNode;
}

export const CardBody:FC<CardBodyProps> = ({className,children}) => {
    return <div className={clsxm("bg-main rounded-[25px] text-white p-[22px] lg:py-[57px] lg:px-[61px] box-border",className)}>
        {children}
    </div>
}