import {FC, ReactNode} from 'react';
import clsxm from "../../../lib/clsxm";
interface ButtonProps {
    className?:string;
    children: ReactNode;
    onClick?:()=>void;
}

export const Button:FC<ButtonProps> = ({className, children, onClick}) => {
    return (
        <button onClick={onClick} className={clsxm("bg-gradient-green-to-blue text-black flex items-center justify-center font-[500]",className)}>
            {children}
        </button>
    )
}