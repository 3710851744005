import {FC, ReactNode} from 'react';
import clsxm from "../../../lib/clsxm";

interface CardFooterProps {
    className?: string;
    children?: ReactNode;
}

export const CardFooter:FC<CardFooterProps> = ({className, children}) => {
    return (
        <div className={clsxm("card-footer",className)}>
            {children}
            {/*<div className="card-footer-inner">*/}
            {/*    <div className="card-footer-inner-left">*/}
            {/*        <div className="card-footer-inner-left-text">Footer</div>*/}
            {/*    </div>*/}
            {/*    <div className="card-footer-inner-right">*/}
            {/*        <div className="card-footer-inner-right-text">Footer</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}