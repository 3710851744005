import React from 'react';
import {Home, MainLayout} from "./components";

function App() {
  return (
        // <MainLayout>
            <Home />
        // </MainLayout>
  );
}

export default App;
