export const Disclaimer = () => {
  return (
    <div className='dark-red-gradient w-full pt-20 sm:pt-0 md:pt-20 xl:pt-[130px]'>
      <div className='mt-10 mb-10 sm:mt-[32px] md:mb-[97px] md:mt-[50px]'>
        <div className='privacy-terms max-w-[1760px] px-5 sm:px-[30px] md:mx-auto md:w-4/5'>
          <h1>$POOR Memecoin Disclaimer</h1>
          <h2>Introduction</h2>
          <p>
            This disclaimer ("Disclaimer") is crucial for anyone considering engaging with, purchasing, or trading $POOR memecoin ("Token"). By interacting with the Token, you consent to the stipulations of this Disclaimer. If you disagree with these terms, you are prohibited from engaging with, buying, or trading the Token.
          </p>
          <h2>No Utility & High Risk</h2>
          <p>
            $POOR is a digital asset recognized as a memecoin, designed primarily for parody and devoid of inherent utility or value. The Token exists for entertainment and speculative aims only and should not be viewed as an investment, currency, or a possession of value.
          </p>
          <h2>Speculative Nature</h2>
          <p>
            The price of $POOR may exhibit significant volatility and unpredictability. While there's potential for price appreciation, there's equally a risk of substantial depreciation or the Token becoming valueless. Only allocate funds that you can afford to lose entirely without impacting your financial stability or wellbeing.
          </p>
          <h2>No Responsibility for Price or Value</h2>
          <p>
            The creators, developers, and promoters of $POOR bear no responsibility for the Token's price or value. No assurances or guarantees are made regarding the Token's present or future value or its price fluctuations.
          </p>
          <h2>Celebrity Endorsements</h2>
          <p>
            Any mentions or appearances by public figures are strictly for entertainment and should not be construed as endorsements. These individuals are neither advisors nor stakeholders in the Token's management or promotion efforts.
          </p>
          <h2>Legal Compliance</h2>
          <p>
            It is your responsibility to ensure that your engagement with, purchase of, or trading in $POOR is in full compliance with the laws and regulations of your jurisdiction. The legality of the Token may vary, and no assertions or warranties regarding its legal standing are provided.
          </p>
          <h2>Investment Risks</h2>
          <p>
            Participating in the memecoin market carries significant risks, including financial loss, potential for fraud, and regulatory scrutiny. It's imperative to perform thorough due diligence and seek advice from financial, legal, and tax experts prior to involvement with the Token.
          </p>
          <h2>No Affiliation</h2>
          <p>
            $POOR has no affiliation, association, authorization, or endorsement from any established entities or their subsidiaries. Its designation is used for satirical purposes and should not be misinterpreted as an attempt to falsely represent any affiliations or endorsements.
          </p>
          <br />
          <p>
            By acquiring, holding, or trading $POOR, you acknowledge your comprehensive understanding and agreement with this Disclaimer. You recognize the inherent risks of engaging with the Token and agree that the Token, alongside its creators, developers, or promoters, will not be held accountable for any losses or damages, direct or indirect, that may arise from your use or inability to use the Token.
          </p>
        </div>
      </div>
    </div>
  )
};