import { useState } from "react";
import {APP_NAME} from "../../../constants";
import {BodyLayout, MainLayout} from "../../core";

export const HomeAbout = () => {
    return <div id="#about">
    <div className="relative w-full pt-[90px] lg:pt-[260px]">
            <BodyLayout>
                <div className="relative flex flex-col lg:flex-row items-center w-full justify-center mb-[50px] lg:mb-[255px]">
                    <div className="relative lg:w-[682px] h-[383px] z-[2]">
                        <iframe width="682" height="383" src="https://www.youtube.com/embed/B5if2hthPCs?si=rwWkZCtCSiJ-2zwP" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>

                    <div className="text-white text-[32px] lg:ml-[40px] text-center mt-[20px] lg:mt-0 lg:text-left">
                        <p className="">We call them</p>
                        <div className="text-[120px] lg:text-[147px] font-[400] font-ramaraja">{APP_NAME}</div>
                    </div>

                    <img src="/images/home-about/colour-box_1.svg" className="absolute hidden lg:block left-[18%] top-[20px]" />
                    <img src="/images/home-about/colour-box_2.svg" className="absolute hidden lg:block left-[33%] top-[-35px]" />
                </div>
            </BodyLayout>


        <BodyLayout>
            <div className='space-y-[20px] text-center flex flex-col items-center relative z-[2]'>
                <div className='uppercase text-[45px] lg:text-[128px] text-transparent bg-gradient-green-to-blue bg-clip-text'>$POORNOMICS</div>
                <p className='text-white text-[24px] font-bold'>0% buy/sell tax, we know you poor.</p>
            </div>
        </BodyLayout>
        <img src="/images/home-about/halo-left.svg" className="absolute hidden lg:block left-0 top-0 z-[1]" />
        <img src="/images/home-about/green-halo.svg" className="absolute hidden lg:block right-0 top-0" />
        <img src="/images/home-about/colour-line_right.svg" className="absolute hidden lg:block right-0 top-[30%]" />


    </div>
    </div>
}